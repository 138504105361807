import { readState } from "@/__main__/app-state.mjs";
import { MAX_TIME } from "@/__main__/constants.mjs";
import getData, { readData } from "@/__main__/get-data.mjs";
import { mergeLocalWithApi } from "@/app/util.mjs";
import type { MatchFull, MatchStart } from "@/data-models/csgo-game-events.mjs";
import CsgoMatch from "@/data-models/csgo-match.mjs";
import type { Matchlist } from "@/data-models/csgo-matches.mjs";
import CsgoMatches from "@/data-models/csgo-matches.mjs";
import type { CsgoPlayer } from "@/data-models/csgo-player.mjs";
import CsgoPlayerModel from "@/data-models/csgo-player.mjs";
import * as API from "@/game-csgo/api.mjs";
import { GAME_MODES } from "@/game-csgo/constants.mjs";
import {
  addMatchesToMatchlists,
  getGameModeByMatch,
  getModeParams,
} from "@/game-csgo/utils.mjs";
import { devError } from "@/util/dev.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

function getExpirationTime(profileId) {
  const isOwnAccount =
    !profileId || Boolean(readState.settings.loggedInAccounts.csgo[profileId]);

  return !isOwnAccount ? null : MAX_TIME;
}

async function fetchData([profileIdParam, matchIdParam], searchParam) {
  const steamId = profileIdParam ?? searchParam.get("profileId");
  const matchId = matchIdParam ?? searchParam.get("matchId");
  const expiryTime = getExpirationTime(steamId);

  try {
    const profile: CsgoPlayer = steamId
      ? (readState.settings.loggedInAccounts.csgo[steamId] ??
        (await getData(
          API.getPlayer({ steamId }),
          CsgoPlayerModel,
          ["csgo", "profiles", steamId],
          {
            expiryTime: expiryTime,
            mergeFn: mergeLocalWithApi,
          },
        )))
      : readState.settings.loggedInAccounts.csgo[
          readState.settings.lastLoggedInIdByGame.csgo
        ];
    if (!profile) return;

    const match: MatchStart | MatchFull | undefined = matchId
      ? (readState.csgo.matches[matchId] ??
        (await getData(
          API.getMatch({ csgoMatchId: matchId }),
          CsgoMatch,
          ["csgo", "matches", matchId],
          {
            shouldFetchIfPathExists: false,
            expiryTime: expiryTime,
            mergeFn: mergeLocalWithApi,
          },
        ).then((m) => {
          addMatchesToMatchlists(profile.steamId, [m]);
          return m;
        })))
      : // Prefer liveGame since that should be truthy when there is a live game being played
        (readState.csgo.liveGame ?? readState.csgo.matches[matchId]);

    // Fetch all players in this match
    const steamIds: string[] = Object.keys(
      (match?.rounds ?? []).reduce((acc, cur) => {
        // No duplicate STEAM_IDs
        for (const i of cur.playerMatchRoundStats) acc[i.steamId] = 0;
        return acc;
      }, {}),
    );
    for (const steamId of steamIds) {
      try {
        await getData(
          API.getPlayer({ steamId }),
          CsgoPlayerModel,
          ["csgo", "profiles", steamId],
          {
            shouldFetchIfPathExists: false,
            mergeFn: mergeLocalWithApi,
          },
        );
      } catch {
        devError(`Failed to fetch CS2 player with steam id: ${steamId}`);
      }
    }

    const gameMode = getGameModeByMatch(match) ?? GAME_MODES.ALL;
    fetchMatchlistData(profile.steamId, gameMode.id);

    // Overlay
    const lastGameMode = await readData(["csgo", "lastGameMode"], {
      forceReadFromDB: true,
    });
    if (isRouteOverlay() && lastGameMode && lastGameMode.id !== gameMode.id)
      fetchMatchlistData(profile.steamId, lastGameMode.id);
  } catch (e) {
    devError(e);
  }
}

/**
 * Load matchlist and all match data for the given profile and
 * game mode.
 */
async function fetchMatchlistData(profileId, gameModeId) {
  const expiryTime = getExpirationTime(profileId);

  const matchlist: Matchlist[] = await getData(
    API.getMatchlist({
      ...getModeParams(gameModeId),
      steamId: profileId,
    }),
    CsgoMatches,
    undefined,
  );

  const matches = await Promise.all(
    matchlist.map((match) =>
      getData(
        API.getMatch({ csgoMatchId: match.matchId }),
        CsgoMatch,
        ["csgo", "matches", match.matchId],
        {
          shouldFetchIfPathExists: false,
          expiryTime: expiryTime,
          mergeFn: mergeLocalWithApi,
        },
      ),
    ),
  );
  addMatchesToMatchlists(profileId, matches);

  return { matchlist, matches };
}

export default fetchData;
